import Swiper from "swiper/bundle";

const [ w, d ] = [window, document];

const servicePhotoSlider = () => {
  new Swiper('.service-photo__slider[data-plugin="slider"]', {
    speed: 800,
    loop: true,
    slidesPerView: 1.3,
    centeredSlides: true,
    spaceBetween: -30,
    autoplay:{
      delay: 4000,
      disableOnInteraction: false,
      waitForTransition: false
    },
    breakpoints: {
      900: {
        slidesPerView: 2.25,
        spaceBetween: -30,
      },
    },
  });
};

const domReady = TPL => false;

const jqInit = () => false;

const onLoadHtml = () => servicePhotoSlider();

export default function SERVICE () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );
  
  $(() => jqInit());
  $(() => Promise.all([w.load['loadHtml']]).then( onLoadHtml ));

}
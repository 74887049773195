import GET_ARTICLES from "../class/getArticles";
import Swiper from "swiper/bundle";

const [ w, d ] = [window, document];

class Blocks {
  constructor() {
    this.init();
  }

  init() {
    this.ticker();
    this.news();
  }

  mainvisual() {
    //メインスライダー
    new Swiper('.mainvisual-slider[data-plugin="slider"]',{
      loop: true,
      effect: 'fade',
      speed: 1200,
      fadeEffect: {
        crossFade: true, // クロスフェードを有効にする（フェードモードの場合 true 推奨）
      },
      autoplay:{
        delay: 4500,
        disableOnInteraction: false,
        waitForTransition: false
      },
      pagination: {
        el: ".mainvisual-slider__pager",
        clickable: true,
      },
    });
  }

  mainCatch() {
    //mainvisual キャッチにクラス付与
    d.querySelector('.mainvisual-catch').classList.add('is-start');
  }

  //ticker
  ticker() {
    const getArticle = new GET_ARTICLES('.block-ticker__article[data-plugin="getArticles"]');
    getArticle.makeItem = (item,content) => `
      <a href="${item.href}" class="block-ticker__link">
        <h2 class="block-ticker__title">NEWS</h2>
        <!-- /.block-ticker__title -->
        <div class="date-cate">
          <span class="date">${item.date}</span>&nbsp;|&nbsp;<span class="cate">${item.category.name}</span>
        </div>
        <h3 class="subject">${item.title.str_truncate(30)}</h3>
      </a>`;
    getArticle.render();
  }

  news() {
    const getArticle = new GET_ARTICLES('.block-news__list[data-plugin="getArticles"]');
    getArticle.makeItem = item => `
    <a href="${item.href}" class="block-news-item">
      <figure class="block-news-item__thumb">
        <img src="${item.thumb}" alt="${item.title}" class="block-news-item__img" width="118" height="118" loading="lazy">
      </figure>
      <!-- /.block-news-item__thumb -->
      <div class="block-news-item__body">
        <span class="date-cate">
          <span class="date">${item.date}</span> | ${item.category ? `<span class="category">${item.category.name}</span>` : ''}
        </span>
        <!-- /.date-cate -->
        <span class="title${item.is_new? ' is-new':''}">${item.title.str_truncate(24)}</span>
      </div>
      <!-- /.block-news-item__body -->
    </a>`;
    getArticle.render();
  }
}

const domReady = TPL => {
  let blocks = new Blocks;
  w.addEventListener('load', () => {
    blocks.mainvisual();
    blocks.mainCatch();
  });
};

const jqInit = () => false;

export default function HOME () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );
  
  $(() => jqInit());
}
import Utility from "./class/utility";
import CUSTOMIZE from "./extended_class/customize";
import ABOUTUS from "./module/aboutus";
import HOME from "./module/home";
import RECRUIT from "./module/recruit";
import SERVICE from "./module/service";
import SUPPORT from "./module/support";

// ユーティリティクラスのインスタンス化
const UTIL = new Utility();

// テンプレートインスタンス化（promise登録）
const TPL = new CUSTOMIZE();

// URLによる関数の実行
UTIL.loader( (request, path) => {
  switch( path ){
    case '/': HOME(); break
    case '/aboutus/': ABOUTUS(); break
    case '/service/': SERVICE(); break
    case '/support/': SUPPORT(); break
    case '/recruit/': RECRUIT(); break
  }
  // switch( request ){}
});

// テンプレートインスタンスの初期化（イベント発火）
TPL.init();
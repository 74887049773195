import Swiper from "swiper/bundle";
import { Modal } from 'bootstrap';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const [ w, d, mq ] = [window, document, window.matchMedia( "(max-width: 899px)" ) ];

const modalWindow = () => {
  gsap.registerPlugin(ScrollTrigger);
  const modal = new Modal(d.getElementById('credo-modal'));
  const openTriggers = d.querySelectorAll('.btn.open');

  const swiper = new Swiper('.dialog-slider[data-plugin="slider"]',{
    autoplay: {
      delay: 3000
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    }
  });

  const toggleModal = trigger => {
    const modalIndex = trigger.dataset.slideIndex || 0;
    trigger.addEventListener("click", () => {
      modal.show();
      swiper.slideTo(modalIndex);
      swiper.autoplay.stop();
    }, false);
  }
  
  openTriggers.forEach( toggleModal );

  ScrollTrigger.create({
    trigger: d.querySelector('.aboutus-credo'),
    start: 'bottom bottom',
    onEnter: () => {
      modal.show();
      setTimeout( swiper.autoplay.start, 500);
    },
    once: true,
    markers: false
  });

};

const domReady = TPL => false;

const jqInit = () => false;

const onLoadHtml = () => {

  if( d.body.classList.contains('is--article-1') ) modalWindow();

};

export default function ABOUTUS() {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );
  $(() => jqInit());
  
  $(() => Promise.all([w.load['loadHtml']]).then( onLoadHtml ) );
}
import Vue from 'vue/dist/vue.esm';
import GET_ARTICLES from '../class/getArticles';

const [ w,d ] = [window, document];

export class LIST {
  constructor (el) {

    // アイテム用コンポーネント
    this.item = {
      template: '#item-template',
      props : {
        item: Object
      },
      filters: {
        truncate : this.truncate
      }
    }

    // リストコンテナ用コンポーネント
    this.list = {
      template: '#list-template',
      components: {
        item: this.item
      },
      props : {
        items: Array
      }
    }

    // メイン
    this.app = {
      el,
      components: {
        list: this.list
      },
      data: {
        items: [],
        categories: [],
      },
      created (){
        let content = 'recruit';
        let urlObj = new URL(location);
        this.fetchCategories(content);
        if( urlObj.searchParams.has('cat') ){
          let category_in = urlObj.searchParams.get('cat');
          this.fetchArticles({ content, category_in });
        }else {
          this.fetchArticles({ content });
        }
      },
      methods: {
        fetchCategories: this.fetchCategories,
        fetchArticles: this.fetchArticles
      }
    }
    new Vue(this.app);
  }
  fetchCategories (content) {
    fetch(`/assets/api/getCategories/?content=${content}`).then(r => r.json())
    .then(res => this.categories = res||[]);
  }
  fetchArticles (opt){
    fetch(`/assets/api/getArticles/?${ new URLSearchParams(opt)}`).then(r => r.json())
    .then(res => this.items = res);
  }
  // 文字数を指定した数になるまで指定した文字列を省略する
  truncate(value, length, omission) {
    if(!value.length) return '';
    var length = length ? parseInt(length, 10) : 20;
    var ommision = omission ? omission.toString() : '...';
  
    if (value.length <= length) {
      return value;
    }
    return value.substring(0, length) + ommision;
  }
}

export class BLOCK {
  constructor(el){
    this.newitem()
  }
  newitem() {
    const getArticle = new GET_ARTICLES('.recruit-voice-block-itemlist[data-plugin="getArticles"]');
    getArticle.makeItem = (item) => `
      <li class="recruit-voice-item">
        <a href="${item.href}" class="recruit-voice-item__link">
          <div class="recruit-voice-item__thumb"><img src="${item.thumb}" alt="${item.title}" class="img"></div>
          <!-- /.voice-item__thumb -->
          <div class="recruit-voice-item__body">
            <span class="desc">${item.desc.replace(/\|/g,'<br />')}</span>
            <span class="title">${item.title}</span>
          </div>
          <!-- /.voice-item__body -->
        </a>
        <!-- /.voice-item__link -->
      </li>
      <!-- /.voice-item -->`;
    getArticle.render();
  }
}
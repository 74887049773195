import GET_ARTICLES from "../class/getArticles";

const [ w, d ] = [window, document];

const domReady = TPL => false;

const jqInit = () => false;

const onLoadHtml = () => {
  
  const news_block = new GET_ARTICLES('.support-news__list[data-plugin="getArticles"]');
  news_block.makeItem = item => `
  <a href="${item.href}" class="support-news-item">
    <figure class="support-news-item__thumb">
      <img src="${item.thumb}" alt="${item.title}" class="support-news-item__img" width="118" height="118" loading="lazy">
    </figure>
    <!-- /.support-news-item__thumb -->
    <div class="support-news-item__body">
      <span class="date-cate">
        <span class="date">${item.date}</span> | ${item.category ? `<span class="category">${item.category.name}</span>` : ''}
      </span>
      <!-- /.date-cate -->
      <span class="title${item.is_new? ' is-new':''}">${item.title.str_truncate(24)}</span>
    </div>
    <!-- /.support-news-item__body -->
  </a>`;
  news_block.render();

};


export default function SUPPORT () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );
  
  $(() => jqInit());
  $(() => Promise.all([w.load['loadHtml']]).then( onLoadHtml ));
}
import GET_ARTICLES from "../class/getArticles";
import {LIST,BLOCK} from "./recruit-voice";

const [ w, d ] = [window, document];

const domReady = TPL => false;

const jqInit = () => {
  if( d.querySelector('#voice-list') ) new LIST('#voice-list');
  if( d.querySelector('.recruit-voice-block') ) new BLOCK();
};

const onLoadHtml = () => {
  const voice_block = new GET_ARTICLES('.recruit-voice-index__list[data-plugin="getArticles"]');
  voice_block.makeItem = item => `
  <a href="${item.href}" class="recruit-voice-index-item">
    <figure class="recruit-voice-index-item__thumb">
      <img src="${item.thumb}" alt="${item.title}" class="recruit-voice-index-item__img" width="118" height="118" loading="lazy">
    </figure>
    <!-- /.recruit-voice-index-item__thumb -->
    <div class="recruit-voice-index-item__body">
      <span class="desc">${item.desc.replace(/\|/g,'<br />')}</span>
      <span class="title${item.is_new? ' is-new':''}">${item.title.str_truncate(24)}</span>
    </div>
    <!-- /.recruit-voice-index-item__body -->
  </a>`;
  voice_block.render();
};


export default function RECRUIT () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );
  $(() => jqInit());
  $(() => Promise.all([w.load['loadHtml']]).then( onLoadHtml ));
}